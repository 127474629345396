import React from "react";
import PropTypes from "prop-types";
import Map from "./Map";
import ContactInfo from "../ContactInfo";
import ContactForm from "../ContactForm";
import SEO from "../SEO";
import Hours from "../Hours";
import { FormattedMessage } from "react-intl";

const ContactPageTemplate = ({ title, meta_title, meta_description }) => {
  return (
    <div>
      <SEO title={meta_title} description={meta_description} />
      <section className="section">
        <div className="container">
          <h3 className="title">{title}</h3>
          <div className="columns">
            <div className="column is-6 is-centered pr-16">
              <ContactForm />
            </div>
            <div className="column is-6 is-centered">
              <h4 className="title is-4 is-centered">
                <FormattedMessage id="contactInfo" />
              </h4>
              <ContactInfo component="contact-page" />
              <Hours />
            </div>
          </div>
          <div className="columns">
            <div className="column is-12">
              <Map height="300px" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
};

export default ContactPageTemplate;
