import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ContactPageTemplate from "../components/ContactPageTemplate";
import Layout from "../components/Layout";

const ContactPage = ({ data, path }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout path={path}>
      <ContactPageTemplate
        title={frontmatter.title}
        meta_title={frontmatter.meta_title}
        meta_description={frontmatter.meta_description}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
  path: PropTypes.string,
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        heading
      }
    }
  }
`;
