import PropTypes from "prop-types";
import React from "react";
import { injectIntl } from "react-intl";

const ContactForm = ({ intl: { formatMessage } }) => {
  return (
    <form
      className="contact-form"
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      encType="application/x-www-form-urlencoded"
    >
      <input type="hidden" name="form-name" value="contact" />
      <label className="has-text-weight-bold" htmlFor="name">
        {formatMessage({ id: "Name" })}
      </label>
      <input
        type="text"
        className="input"
        id="name"
        name="name"
        required
        autoComplete="name"
      />
      <label className="has-text-weight-bold" htmlFor="email">
        {formatMessage({ id: "Email" })}
      </label>
      <input
        className="input"
        id="email"
        type="email"
        name="email"
        required
        autoComplete="email"
      />
      <label className="has-text-weight-bold" htmlFor="message">
        {" "}
        {formatMessage({ id: "Message" })}
      </label>
      <textarea className="textarea" id="message" name="message" required />
      <button className="button is-primary" type="submit">
        {formatMessage({ id: "Submit" })}
      </button>
    </form>
  );
};

ContactForm.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

export default injectIntl(ContactForm);
